import { mapGetters } from 'vuex'
import { DateTime } from 'luxon'

export default {
  components: {
    EditUserForm: () => import('@/modules/dynamicForm/EditUserForm'),
    FileField: () => import('@/components/formFields/FileField'),
  },
  props: {
    organizationId: { type: String, default: null },
    projectId: { type: String, default: null },
  },
  computed: {
    ...mapGetters('project', ['terms']),
  },
  methods: {
    async createEnrollment({ userId, setPassword }) {
      const { organizationId, projectId } = this

      const form = this.$refs.subForm.form ? this.$refs.subForm.parseForm() : null
      const data = {
        enrolledBy: this.$store.state.user.dbData.id, // user logged in admin
        id: userId,
        waiver: this.terms.length ? this.form.waiver : null,
        ...(form && ({ info: { form } })),
        role: 'player',
        status: 'pendingApproval',
        userId,
      }
      await this.runAsync(() => this.$store.dispatch('project/createEnrollment', { organizationId, projectId, enrollmentId: userId, data, setPassword }))
    },
  },
}

<template>
  <div v-if="isDataLoaded">
    <h2 class="mb-4" v-text="$t('project.academy.users.competitorData')" />
    <strong class="mb-4" v-text="`${user.firstName} ${user.lastName}`" />
    <div v-if="terms.length">
      <file-field
        v-model="form.waiver" accept="application/pdf,image/*" :label="$t('common.waiver')"
        :max-size="1000000" :error-messages="getFieldErrors('waiver')"
      />
    </div>
    <edit-user-form ref="subForm" :main-title="$t('common.formData')" :data="formattedForm" />
    <div class="d-flex justify-center">
      <v-btn color="primary" min-width="12rem" @click="save" v-text="$t('common.save')" />
    </div>
  </div>
</template>
<script>
import { requiredIf } from 'vuelidate/lib/validators'
import { mapGetters } from 'vuex'
import formUtils from '@/utils/mixins/formUtils'
import dataMixin from './dataMixin'

export default {
  name: 'EmailExists',
  mixins: [formUtils, dataMixin],
  props: {
    user: { type: Object, required: true },
    organizationId: { type: String, required: true },
  },
  data: () => ({ form: { waiver: null }, isDataLoaded: false }),
  computed: {
    ...mapGetters({ membership: 'user/membership' }),
    ...mapGetters({ staticForm: 'dynamicForm/staticData' }),
    formattedForm: ({ staticForm, membership }) => (staticForm?.fields ?? [])
      .filter(f => !f.isInfoOnly).map(f => ({ id: f.id, value: membership.organization?.form?.[f.id] })),
  },
  async created() {
    const { organizationId } = this
    this.$store.commit('loader/show')
    await this.$store.dispatch('user/bindOrganizationMembership', { id: this.user.id, role: 'subscriber', organizationId })
    this.$store.commit('loader/hide')
    this.isDataLoaded = true
  },
  validations() {
    return {
      form: { waiver: { required: requiredIf(() => this.terms.length) } },
    }
  },
  methods: {
    async save() {
      if (this.$refs.subForm.form) this.$refs.subForm.$v.form.$touch()
      if (!this.isFormValid() || (this.$refs.subForm.form && !this.$refs.subForm.isFormValid())) {
        this.scrollToFirstError()
        return
      }
      await this.createEnrollment({ userId: this.user.id, setPassword: false })
      this.$emit('close-dialog')
    },
  },
}
</script>
